<template>
  <el-container class="patroladd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统参数配置</el-breadcrumb-item>
       <el-breadcrumb-item>页面参数分类</el-breadcrumb-item>
       <el-breadcrumb-item>模板属性</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.page_attr_id">编辑模板属性</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增模板属性</el-breadcrumb-item>
          </el-breadcrumb>
        <div>
            
            <el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15 pt0">
     <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">模板属性信息</el-col>
          <el-col :span="11" class="clear">
            <el-form-item  class="rules w" size="medium" label-width="28%" label="模板类型：" >
              <el-select disabled v-model="form.page_module_id" clearable placeholder="请选择模板类型">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.page_module_id" :label="item.page_module_name" :value="String(item.page_module_id)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        <el-col :span="11" class="tl clear" >
        <el-form-item  class="rules w" label-width="28%" label="属性中文名："  size="medium"  prop="attr_ch" :rules="[{required: true,message: '请输入属性中文名', trigger: 'blur' }]">
           <el-input  style="width:100%" placeholder="请输入属性中文名" v-model="form.attr_ch" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl clear" >
        <el-form-item  class="rules w" label-width="28%" label="属性英文名："  size="medium"  prop="attr_en" :rules="[{required: true,message: '请输入属性英文名', trigger: 'blur' }]">
           <el-input  style="width:100%" placeholder="请输入属性英文名" v-model="form.attr_en" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        
          <el-col :span="22" class="tl" :offset="1">
           <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
        </el-col>
     </el-row>
     </el-form>
    </el-main>
  </el-container>
</template>
<script>
import Vue from 'vue'
import axios from '../../../util/api'
import {session } from "../../../util/util";
export default {
  name:'patroladd',
  data () {
    return {
        form: {
          attr_ch:'',
            attr_en:"",
            page_module_name:'',
            page_module_id:'',
            page_attr_id:'',
      },
        statusOptions: [],
    }
    
  },
     created () {
        this.form.page_module_id=session.get('params1').id?String(session.get('params1').id):'';
        this.form.page_attr_id=session.get('params1').id1?String(session.get('params1').id1):'';
        this.getModule(this.form.page_module_id)
        if(this.form.page_attr_id){ this.init()}
       
  },
  methods: {
    init(){
        axios.get('/pc/page-attr/single',{page_attr_id:this.form.page_attr_id}).then((v) => {
      this.form=v.data.page_attr
      this.form.page_module_id=String(v.data.page_attr.page_module_id);
      this.form.page_attr_id=String(v.data.page_attr.page_attr_id);
      })
    },
    getModule(params){
         axios.get('/pc/page-module/all').then((v) => {   
             this.statusOptions=v.data.page_module_list;
           var obj = {}
      obj = v.data.page_module_list.find(item=>{
        return item.page_module_id == params;
      })
       this.form.page_module_name=obj.page_module_name
      })
    },
     submitForm(form){
          let paramas=this.form;
          let paramas1={
            page_attr_id:paramas.page_attr_id,
            attr_ch:paramas.attr_ch,
            attr_en:paramas.attr_en,
          }
         this.$refs[form].validate((valid) => {
        if (valid) {
            axios.get("/pc/page-attr/exist", paramas1).then(v => {
              if(this.form.page_attr_id){
 axios.put('/pc/page-attr/update', paramas).then((v) => {
           this.$router.back()
          }) 
              }else{
                 axios.post('/pc/page-attr/save', paramas).then((v) => {
           this.$router.back()
          }) 
              }
               
                 });

           
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      }
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
}

</style>
